import React, { useReducer, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import appReducer, { initialState } from "./containers/App/reducer";
import Jackpot from "./components/jackpot/Jackpot";
import "./index.css";
import "./assets/styles/lucky-six.css";
import Drum from "./components/drum/Drum";

import BetContent from "./components/BetContent/BetContent";
import FirstScreen from "./components/BetContent/FirstScreen"
import Timer from "./components/timer/Timer";
import Statistics from "./components/statistics/Statistics";
import NextDrawProgressBar from "./components/timer/NextDrawProgressBar";
// import CompanyName from "./components/companyName/CompanyName";
// import Preloader from "./components/Preloader/Preloader";

import ModalWindows from "./components/ModalWindows";

import GlobalDataContext from "./GlobalDataContext";
import {
  SET_LIFECYCLE_DATA,
  JACKPOT_UPDATE,
  INIT_TVSCREEN,
  SET_LIFECYCLE_STATE,
  JACKPOT_HIT,
  UPDATE_WINNING_PLAN_DATA
} from "./containers/App/constantActions";
import { lfstates } from "./utils/constants";
import ResultsTable from "./components/resultsTable/ResultsTable";

const AppIndex = () => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const [showModal, setShowModal] = useState(null);

  const $lifecycleData =   document.getElementById("lifecycleData");
  const $jackpotStatus =   document.getElementById("jackpotStatus");
  const $tvscreen =   document.getElementById("tvscreen");

  useEffect(() => {
    $lifecycleData.addEventListener("receiveMessage", () => {
      const message = JSON.parse($lifecycleData.dataset.lifecycleData)
        console.log("lifecycledata", message);
        if (message.LifeCycleStatus === "JACKPOT_HIT") {
          dispatch({
            type: JACKPOT_HIT,
            payload: message
          });
          setShowModal("jackpotHit");
        }
        else if (message.LifeCycleStatus === "DRAW") {
            dispatch({
              type: SET_LIFECYCLE_DATA,
              payload: message
            });
            dispatch({
              type: SET_LIFECYCLE_STATE,
              payload: lfstates.DRAW
            });
        }
        else if (message.LifeCycleStatus === "WINNINGPLAN") {
            dispatch({
              type: UPDATE_WINNING_PLAN_DATA,
              payload: message
            });
            dispatch({
              type: SET_LIFECYCLE_STATE,
              payload: lfstates.WINNINGPLAN
            });
        }
      });

    $jackpotStatus.addEventListener("receiveMessage", () => {
        const jackpotStatus = $jackpotStatus.dataset.jackpotStatus;
        dispatch({
          type: JACKPOT_UPDATE,
          payload: JSON.parse(jackpotStatus)
        });
      });
    $tvscreen.addEventListener("receiveMessage", () => {
        const tvscreen = $tvscreen.dataset.initTvscreen;
        console.log("tvscreen", JSON.parse(tvscreen));
        dispatch({
          type: INIT_TVSCREEN,
          payload: JSON.parse(tvscreen)
        });
      });

    return () => {
      $lifecycleData.removeEventListener("receiveMessage");
      $jackpotStatus.removeEventListener("receiveMessage");
      $tvscreen.removeEventListener("receiveMessage");
    }

  }, [$lifecycleData, $jackpotStatus, $tvscreen]);

  let logoContainer = state.currentLFState === lfstates.BET ? "logo-container" : "game-timer";

  return (
    <GlobalDataContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <I18nextProvider i18n={i18n}>
        
        <div className="constraint-16-9">
          <div className="game-screen-model game-screen-background">
            <div
              id="head"
              className="header fli-1 fl-cntr pos-rel overflow-hidden"
            >
              <div className="logo-container fli-1 fl-cntr fl-cntr-col">
                <div className="logo" />
              </div>
              <div className="logo-container fli-2 fl-cntr fl-cntr-col">
                <div className="game-name" />
              </div>
              <div className="fli-9 fl-cntr fl-cntr-col">
                <Jackpot jackpotValue={state.jackpotstatus.Jackpot} />
              </div>
              <div className={`${logoContainer} fli-3 fl-cntr fl-cntr-col`}>
                {/* {state.currentLFState === lfstates.DRAW && <CompanyName />} */}
                {state.currentLFState === lfstates.BET && (
                  <Timer timeToDrum={state.winningPlanData.Countdown} />
                )}
              </div>
            </div>

            <div className="gameBody fli-15 fl-cntr pos-rel">
              {!state.isDataFetched && <FirstScreen/>}
              {state.currentLFState === lfstates.RESULT && <ResultsTable />}
              {state.currentLFState === lfstates.BET && <BetContent />}
              {state.currentLFState === lfstates.DRAW && <Drum />}
            </div>

            <div className="footer fli-1 fl-cntr pos-re">
              {state.currentLFState === lfstates.BET && (
                <NextDrawProgressBar
                  time={(state.winningPlanData.Countdown * 1000) - 1000}
                />
              )}
              {state.currentLFState === lfstates.DRAW && <Statistics />}
            </div>
          </div>
        </div>
        <ModalWindows
          showModal={showModal}
          toggleModal={showModal => setShowModal(showModal)}
        />
      </I18nextProvider>
    </GlobalDataContext.Provider>
  );
};

ReactDOM.render(<AppIndex />, document.getElementById("root"));
