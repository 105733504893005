/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from "immer";
import {
  INIT_TVSCREEN,
  SET_LIFECYCLE_DATA,
  BET,
  DRAW,
  RESULTS,
  JACKPOT_UPDATE,
  JACKPOT_HIT,
  SET_LIFECYCLE_STATE,
  UPDATE_RESULT_LIVE_STATUS,
  UPDATE_WINNING_PLAN_DATA
} from "./constantActions";

// The initial state of the App
export const initialState = {
  isDataFetched: false,
  inittvscreen: { RoundDelay: 0, Language: "en", Logo: null, Countdown: 0 },
  currentLFState: '',
  lifecycleData: {
    "LifeCycleStatus": "DRAW",
    "Round": 0,
    "Entries": [
    ],
    "StarsList": [
        0,
        0
    ],
    "PreNumbersUOText": "-1",
    "FirstNumberUOText": "-1"
  },
  winningPlanData: {
    "LifeCycleStatus": "WINNINGPLAN",
    "Round": 0,
    "WinningPlan": [
        {
            "BetType": 1,
            "Specifier": 0,
            "Value": 0.0
        }
    ],
    "WinningNumbers": [],
    "minStake": -1,
    "maxStake": -1,
    "minSystemStake": -1,
    "Countdown": 0,
    "PreNumbersUOText": "",
    "FirstNumberUOText": ""
},
  jackpotstatus: { Jackpot: null },
  jackpotHit: {
    isHitted: false
  },
  resultLiveStatus: {
    preNumberSum: { value: 0, preNumFinished: false },
    colorsDiagram: [
      { color: "red", count: 0 },
      { color: "green", count: 0 },
      { color: "blue", count: 0 },
      { color: "purple", count: 0 },
      { color: "brown", count: 0 },
      { color: "yellow", count: 0 },
      { color: "orange", count: 0 },
      { color: "black", count: 0 }
    ],
    eventOddCount: { even: 0, odd: 0 }
  }
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INIT_TVSCREEN:
        draft.inittvscreen = { ...action.payload };
        break;
      case SET_LIFECYCLE_DATA:
        draft.lifecycleData = { ...action.payload };
        break;
      case SET_LIFECYCLE_STATE:
        draft.currentLFState = action.payload;
        draft.isDataFetched = true;
        // console.log("state -> ", action.payload, "timestamp -> ", Date.now());
        break;
      case UPDATE_RESULT_LIVE_STATUS:
        draft.resultLiveStatus = { ...action.payload };
        break;
      case UPDATE_WINNING_PLAN_DATA:
        draft.winningPlanData = { ...action.payload };
        break;
      case BET:
        break;
      case DRAW:
        break;
      case RESULTS:
        break;
      case JACKPOT_UPDATE:
        draft.jackpotstatus = { ...action.payload };
        break;
      case JACKPOT_HIT:
        draft.jackpotHit = { ...action.payload, isHitted: true };
        break;
      default:
        return state;
    }
  });

export default appReducer;
