export const INIT_TVSCREEN = "INIT_TVSCREEN";
export const BET = "BET";
export const DRAW = "DRAW";
export const RESULTS = "RESULTS";
export const JACKPOT_UPDATE = "JACKPOT_UPDATE";
export const JACKPOT_HIT = "JACKPOT_HIT";
export const SET_LIFECYCLE_DATA = "SET_LIFECYCLE_DATA";
export const SET_LIFECYCLE_STATE = "SET_LIFECYCLE_STATE";
export const UPDATE_RESULT_LIVE_STATUS = "UPDATE_RESULT_LIVE_STATUS";
export const WINNINGPLAN = "WINNINGPLAN";
export const UPDATE_WINNING_PLAN_DATA = "UPDATE_WINNING_PLAN_DATA";
