import React from "react";
import { useTranslation } from "react-i18next";
import { colorsByNumber, colors } from "./../../utils/constants";
import { useGlobalDataContext } from "./../../GlobalDataContext";
import FirstScreen from "./../../components/BetContent/FirstScreen";

const ResultsTable = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const { state } = useGlobalDataContext();

  const resultColumns = {
    [colors.red]: {
      one: 1,
      nine: 9,
      seventeen: 17,
      "twenty-five": 25,
      "thirty-three": 33,
      "fourty-one": 41,
    },
    [colors.green]: {
      two: 2,
      ten: 10,
      eighteen: 18,
      "twenty-six": 26,
      "thirty-four": 34,
      "fourty-two": 42,
    },
    [colors.blue]: {
      three: 3,
      eleven: 11,
      nineteen: 19,
      "twenty-seven": 27,
      "thirty-five": 35,
      "fourty-three": 43,
    },
    [colors.purple]: {
      four: 4,
      twelve: 12,
      twenty: 20,
      "twenty-eight": 28,
      "thirty-six": 36,
      "fourty-four": 44,
    },
    [colors.brown]: {
      five: 5,
      thirteen: 13,
      "twenty-one": 21,
      "twenty-nine": 29,
      "thirty-seven": 37,
      "fourty-five": 45,
    },
    [colors.yellow]: {
      six: 6,
      fourteen: 14,
      "twenty-two": 22,
      thirty: 30,
      "thirty-eight": 38,
      "fourty-six": 46,
    },
    [colors.orange]: {
      seven: 7,
      fifteen: 15,
      "twenty-three": 23,
      "thirty-one": 31,
      "thirty-nine": 39,
      "fourty-seven": 47,
    },
    [colors.black]: {
      eight: 8,
      sixteen: 16,
      "twenty-four": 24,
      "thirty-two": 32,
      fourty: 40,
      "fourty-eight": 48,
    },
  };

  const getColorNameByNumber = (ballNumber) => {
    let ballColor = "";
    for (const [color, numbers] of Object.entries(colorsByNumber)) {
      if (numbers.includes(ballNumber)) ballColor = color;
    }

    return ballColor;
  };

  const reducer = (acc, curr) =>
    state.lifecycleData.Entries.includes(curr) ? acc + 1 : acc;
  const arrayMaxColorsWin = Object.values(colorsByNumber).map((i) =>
    i.reduce(reducer, 0)
  );
  const maxColorsWin = Math.max.apply(null, arrayMaxColorsWin);
  const addPointerClass = (indexCol) =>
    arrayMaxColorsWin[indexCol] === maxColorsWin ? "column-pointer" : "";

  return state.lifecycleData.Round !== 0 ? (
    <div className="width-90">
      <div className="height-100">
        <div className="next-draw-label">
          {t("resultScreen.resultDraw")} {state.lifecycleData.Round}
        </div>
        <div className="next-draw-line" />
        <div
          className="fli-1 fl-cntr pos-rel overflow-hidden height-100"
          style={{ height: "94%" }}
        >
          <div className="results-container fli-11 fl-cntr pos-rel">
            <div className="result-balls-container fl-cntr fl-cntr-row">
              {Object.keys(resultColumns).map((column, index) => {
                return (
                  <div
                    key={column}
                    className={`results-column column-${column} ${addPointerClass(
                      index
                    )}`}
                  >
                    {Object.keys(resultColumns[column]).map(
                      (wordNumber, index) => {
                        return (
                          <div
                            key={wordNumber}
                            className={`result-balls 
                                ${
                                  Object.keys(resultColumns[column]).length -
                                    1 ===
                                  index
                                    ? `color-alias alias-${column}`
                                    : ""
                                }
                                ${
                                  state.lifecycleData.Entries.includes(
                                    resultColumns[column][wordNumber]
                                  )
                                    ? wordNumber
                                    : "results-spaceholder"
                                }                                            
                                `}
                          >
                            {!state.lifecycleData.Entries.includes(
                              resultColumns[column][wordNumber]
                            ) && (
                              <div className="number-spaceholder">
                                {resultColumns[column][wordNumber]}
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>

            <div className="results-line" />

            <div className="fl-cntr width-100 height-100 fl-cntr-col">
              <div className="fli-1 fl-cntr-vcenter fl-cntr-col">
                <div className="lato-24-light">
                  {t("resultScreen.preballs")} ({t("resultScreen.first5balls")})
                </div>
                <div className="fli-1 fl-cntr fl-cntr-row result-main-balls">
                  {[...state.lifecycleData.Entries].splice(0, 5).map((ball) => {
                    return (
                      <div
                        key={`${Math.random()}_`}
                        className={`ball-for-placeholder ball-${ball}`}
                      ></div>
                    );
                  })}
                </div>
              </div>
              <div className="fli-3 fl-cntr fl-cntr-row">
                <div className="height-inherit fl-cntr-col">
                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.firstBallHightLow")}
                    </div>
                    <p className="lato-36-bold">
                      {state.lifecycleData.Entries[0] > 24.5
                        ? t("resultScreen.high")
                        : t("resultScreen.low")}
                    </p>
                  </div>

                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.firstBallColor")}
                    </div>
                    <p
                      className={`lato-36-${getColorNameByNumber(
                        state.lifecycleData.Entries[0]
                      )} uppercase`}
                    >
                      {t(
                        `colors.${getColorNameByNumber(
                          state.lifecycleData.Entries[0]
                        )}`
                      )}
                    </p>
                  </div>

                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.firstBallEvenOdd")}
                    </div>
                    {/* eslint-disable-next-line */}
                    <p className="lato-36-bold">
                      {state.lifecycleData.Entries[0] % 2 === 0
                        ? t("resultScreen.even")
                        : t("resultScreen.odd")}
                    </p>
                  </div>

                  <div className="last-row-in-result">
                    <div className="lato-36-bold">
                      {t("resultScreen.first")}
                    </div>
                    <div className="star-small star-small-result" />
                    <div
                      className={`margin-top-3 result-balls ball-${
                        state.lifecycleData.Entries[
                          state.lifecycleData.StarsList[0]
                        ]
                      }`}
                    ></div>
                  </div>
                </div>
                <div className="results-line"></div>
                <div className="height-inherit fl-cntr-col">
                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.preballsSum")}
                    </div>
                    <p className="lato-36-bold">
                      {[...state.lifecycleData.Entries]
                        .splice(0, 5)
                        .reduce((a, b) => {
                          return a + b;
                        }) > 122.5
                        ? t("resultScreen.high")
                        : t("resultScreen.low")}
                    </p>
                  </div>

                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.lastBallColor")}
                    </div>
                    <p
                      className={`lato-36-${getColorNameByNumber(
                        state.lifecycleData.Entries[
                          state.lifecycleData.Entries.length - 1
                        ]
                      )} uppercase`}
                    >
                      {t(
                        colors[
                          getColorNameByNumber(
                            state.lifecycleData.Entries[
                              state.lifecycleData.Entries.length - 1
                            ]
                          )
                        ]
                      )}
                    </p>
                  </div>

                  <div className="result-cell">
                    <div className="lato-24-light">
                      {t("resultScreen.moreEvenOddBalls")}
                    </div>
                    <p className={`lato-36-bold`}>
                      {state.resultLiveStatus.eventOddCount.even >
                      state.resultLiveStatus.eventOddCount.odd
                        ? t("resultScreen.even")
                        : t("resultScreen.odd")}
                    </p>
                  </div>

                  {/*<div className="result-cell" style={{opacity: '0'}}>*/}
                  {/*  <div className="lato-24-light">{t('resultScreen.lastBallEvenOdd')}</div>*/}
                  {/*  /!* eslint-disable-next-line *!/*/}
                  {/*  <p className="lato-36-bold">{state.lifecycleData.Entries[state.lifecycleData.Entries.length - 1]%2 == 0  ? t('resultScreen.even') : t('resultScreen.odd')}</p>*/}
                  {/*</div>*/}

                  <div className="last-row-in-result">
                    <div className="lato-36-bold">{t("resultScreen.last")}</div>
                    <div className="star-small star-small-result" />
                    <div
                      className={`result-balls ball-${
                        state.lifecycleData.Entries[
                          state.lifecycleData.StarsList[1]
                        ]
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FirstScreen />
  );
};

export default ResultsTable;
