import React from "react";
import { useTranslation } from "react-i18next";
const HitTheStarsTable = () => {
  const { t } = useTranslation();
  return (
    <div className="luckysix-container">
      {t("betContent.hitSarsTable.hitSars")}
      <div className="pay-table-label-dark pay-table-line">
        
        {t("betContent.hitSarsTable.multiWinnings")}
      </div>
      <div className="pay-table-label-dark-smaller">
      {t("betContent.hitSarsTable.hit1star")} - 2 x  {t("betContent.hitSarsTable.winning")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.hitSarsTable.hit2stars")} - 3 x {t("betContent.hitSarsTable.winning")}
      </div>
      <div className="fl-cntr fl-cntr-row">
        <div className="star-left" />
        <div className="star-right" />
      </div>
    </div>
  );
};

export default HitTheStarsTable;
