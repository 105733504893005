import React from "react";
import { useTranslation } from "react-i18next";

export const Modal = ({
  id,
  showModal,
  children,
  customClass,
  closeModalEventListener,
  restrictCloseModalOnBackdrop
}) => {

  return (
    <div
      id={id}
      className={`modal-wrapper ${showModal ? 'active' : ''}`}
    >
      <div
        className={`modal ${[`${customClass}`] ? customClass : ''} ${showModal ? 'active' : ''}`}
      >
        {children}
      </div>

      {showModal && (
        <div
          className={`modal-backdrop ${showModal ? 'active' : ''}`}
          onClick={
            !restrictCloseModalOnBackdrop ? closeModalEventListener : null
          }
        ></div>
      )}
    </div>
  );
};

export const ModalHeader = ({ children }) => {
  return <div className="modal-header">{children}</div>;
};

export const ModalMainContent = ({ children }) => {
  return <div className="modal-main">{children}</div>;
};

export const ModalFooter = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

export const ModalCloseBtn = ({ children, closeModalEventListener }) => {
  // const onClickEventListener = (e) => {
  //   closeModalEventListener();
  // }
  const { t } = useTranslation();
  return (
    <span onClick={closeModalEventListener} className="modal-close-btn">
      {t("close")}
    </span>
  );
};
