import React from "react";

import JackpotWinnerModal from "./JackpotWinnerModal";
import { useGlobalDataContext } from "./../../GlobalDataContext";


const ModalWindows = ({
    showModal,
    toggleModal
}) => {
  const { state } = useGlobalDataContext();

  return (
    <>
      {showModal === 'jackpotHit' && state.jackpotHit.isHitted &&
        <JackpotWinnerModal
            showModal={true}
            toggleModal={toggleModal}
            jackpotCode={state.jackpotHit.JackpotCode }
            jackpotAmount={state.jackpotHit.Jackpot}
        />}
    </>
  );
};

export default ModalWindows;
