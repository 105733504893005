import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";


const NextDrawProgressBar = ({time}) => {
  const { t } = useTranslation();
  const [progressLine, setProgressLine] = useState(100);

  useEffect(()=>{
    let time_ = time;
    let progressInterval = setInterval(()=>{
      time_ = time_ - 1000;

      setProgressLine((time_ * 100)/time);
      if (time_<= 0) {
        clearInterval(progressInterval)
      }
    }, 1000)
    return () => {
      clearInterval(progressInterval);
      setProgressLine(100);
    }
  },[time])


    return (
      <div className="progress-line">
        <div className="title-progress-line lato-18-semibold">{t("betContent.nextDraw")}</div>
        <div className={'outer-progress-line'} >
          <div className="filler" style={{ width: `${progressLine}%` }} />
        </div>
      </div>
    );

}

export default NextDrawProgressBar;
