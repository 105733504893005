import React from "react";
import { useTranslation } from "react-i18next";
const HurryUpTable = () => {
  const { t } = useTranslation();
  return (
    <div className="luckysix-container">
      {t("betContent.hurryUPTable.hurryUp")}
      <div className="pay-table-label-dark pay-table-line">
        {t("betContent.betsTable.hitSoonerWinMore")}
      </div>
      <div className="pay-table-label-dark-smaller">{t("betContent.hurryUPTable.notMuchTime")}</div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.hurryUPTable.pickNumberColor")}
      </div>
      <div className="pay-table-label-dark-smaller">{t("betContent.hurryUPTable.goodLuck")}</div>
    </div>
  );
};

export default HurryUpTable;
