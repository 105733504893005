import React from "react";

const ColorsDiagram = ({ colorsCounts }) => {
  const MAX_BALLS_COUNT = 6;
  return (
    <div className="progress-bars-container">
      {colorsCounts.map((item) => {
        return (
          <div
            key={item.color}
            className={`filler-outer filler-outer-${item.color}`}
          >
            <div
              className={`filler filler-${item.color}`}
              style={{ height: `${(item.count * 100) / MAX_BALLS_COUNT}%` }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ColorsDiagram;
