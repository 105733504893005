import React, { useEffect, useState, useRef } from "react";
import converter from "number-to-words";
// import start from "../../assets/videos/start.mp4";
import mid from "../../assets/videos/mid.mp4";
// import end from "../../assets/videos/end.mp4";

import {
  SET_LIFECYCLE_STATE
} from "./../../containers/App/constantActions";

import { lfstates, colorsByNumber } from "./../../utils/constants";
import { UPDATE_RESULT_LIVE_STATUS } from "./../../containers/App/constantActions";

import { useGlobalDataContext } from "./../../GlobalDataContext";
import {useTranslation} from "react-i18next";

const Drum = () => {

  const { t } = useTranslation();
  const { state, dispatch } = useGlobalDataContext();
  const [drawResults, setDrawResults] = useState([]);
  const animatedRef = useRef(null);
  // eslint-disable-next-line
  const [videoState, setVideoState] = useState("MIDDLE");
  const { WinningNumbers } = state.winningPlanData;
  const middleBallsRef = useRef();

  useEffect(() => {
    if (state.lifecycleData.LifeCycleStatus === lfstates.DRAW) ballShown();
    return () => {}
    // eslint-disable-next-line
  }, [state.lifecycleData, ]);

  const getColorByBallNumber = (ballNumber) => {
    let ballColor = "";
    for (const [colorName, numberSet] of Object.entries(colorsByNumber)) {
      if (numberSet.includes(ballNumber)) ballColor = colorName;
    }
    return ballColor;
  };

  // delay after last ball drawing before Winingplan data received
  const delayAfterLastBall = 3;
  const durationForOneBall = Math.floor((state.lifecycleData.DrawDuration-delayAfterLastBall)/35*100)/100 - 0.1;
  // console.log('durationForOneBall', Math.floor((state.lifecycleData.DrawDuration-delayAfterLastBall)/35*100)/100)

  const ballShown = () => {
    let ballNumbers =
      state.lifecycleData.Entries !== undefined
        ? state.lifecycleData.Entries
        : null;

    let currentBall = -1;
    let placehodersBalls = [];
    let prenumberSum = 0;
    let evenPreBalls = 0;
    let oddPreBalls = 0;
    const ballCountByColors = {
      red: 0,
      green: 0,
      blue: 0,
      purple: 0,
      brown: 0,
      yellow: 0,
      orange: 0,
      black: 0,
    };

    const showBallInterval = setInterval(() => {
      
      if (currentBall > -1) {
        if (document.getElementById(`animated-${converter.toWords(ballNumbers[currentBall])}`)) document.getElementById(`animated-${converter.toWords(ballNumbers[currentBall])}`).remove();
        placehodersBalls = [
          ...placehodersBalls,
          `ball-${converter.toWords(ballNumbers[currentBall])}`,
        ];

        // count colors by balls
        const currentBallColor = getColorByBallNumber(ballNumbers[currentBall]);
        ballCountByColors[currentBallColor] =
          ballCountByColors[currentBallColor] + 1;

        // do it only for preballs (first 5 balls)
        if (currentBall < 5) {
          if (ballNumbers[currentBall] % 2 === 0) {
            evenPreBalls = evenPreBalls + 1;
          } else {
            oddPreBalls = oddPreBalls + 1;
          }
          prenumberSum = prenumberSum + ballNumbers[currentBall];
        }

        dispatch({
          type: UPDATE_RESULT_LIVE_STATUS,
          payload: {
            colorsDiagram: [
              { color: "red", count: ballCountByColors.red },
              { color: "green", count: ballCountByColors.green },
              { color: "blue", count: ballCountByColors.blue },
              { color: "purple", count: ballCountByColors.purple },
              { color: "brown", count: ballCountByColors.brown },
              { color: "yellow", count: ballCountByColors.yellow },
              { color: "orange", count: ballCountByColors.orange },
              { color: "black", count: ballCountByColors.black },
            ],
            eventOddCount: { even: evenPreBalls, odd: oddPreBalls },
            preNumberSum: {
              value: prenumberSum,
              preNumFinished: currentBall >= 4,
            },
          },
        });

        setDrawResults([...placehodersBalls]);
        // clear interval for last ball
        if (placehodersBalls.length >= 35) {
          clearInterval(showBallInterval); 
          if (document.getElementById(`animated-${converter.toWords(ballNumbers[currentBall])}`)) document.getElementById(`animated-${converter.toWords(ballNumbers[currentBall])}`).remove();        
          setTimeout(()=>{
            dispatch({
              type: SET_LIFECYCLE_STATE,
              payload: lfstates.RESULT
            });
          }, 2000)
          return;
        }
      }

      currentBall = currentBall + 1;
      const el = document.createElement('div');
      el.id=`animated-${converter.toWords(ballNumbers[currentBall])}`;
      el.classList.add(`animated-${converter.toWords(ballNumbers[currentBall])}`, 'position-absolute', 'ball_big', 'ball_big_move');
      // @keyframe moveBigBall described in ball.css styles
      el.style.animation = `moveBigBall ${durationForOneBall}s ease-out`;
      if(middleBallsRef.current) middleBallsRef.current.appendChild(el);
      //document.getElementById('middleBalls')
    }, durationForOneBall*1000);
  };

  const showWinningNumbers = (index) => (WinningNumbers[index - 5] || {}).Value || 1

  const getBallForColumn = (index) => {
    return (
      <div
        key={index}
        className={`label-ball-block ${index > 19 ? "right-align" : ""} ${
          state.lifecycleData.StarsList.includes(index) &&
          drawResults[index] !== undefined &&
          index < 20
            ? "star-outside left"
            : state.lifecycleData.StarsList.includes(index) &&
              drawResults[index] !== undefined &&
              index > 19
            ? "star-outside right"
            : ""
        }`}
      >
        {index > 19 && (
          <div className={`ball-label left}`}>
            {" "}
            { showWinningNumbers(index) }
          </div>
        )}
        <div
          className={`spaceholder ${
            state.lifecycleData.StarsList.includes(index) ? "star-inside" : ""
          }`}
        >
          <div className={`ball-for-placeholder ${drawResults[index]}`} />
        </div>
        {index < 20 && (
          <div className={`ball-label right`}>
            { showWinningNumbers(index) }
          </div>
        )}
      </div>
    );
  };

  return (
      <div className="fli-1 result-row z-index-0 drawn-state">
        <style id="animationKeyframe">

        </style>
        <div className="next-draw-label">
          {t("header.currentDraw")} {state.lifecycleData.Round}
        </div>
        <div className="current-draw-line" />
        <div className="fl-cntr pos-rel height-100">
          <div className="spaceholder-container fli-4 fl-cntr-col justify-flex-end">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 4 && index < 14 && getBallForColumn(index);
            })}
          </div>

          <div className="justify-flex-end spaceholder-container fli-4 fl-cntr-col">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 13 && index < 17 && getBallForColumn(index);
            })}
          </div>

          <div className="justify-flex-end spaceholder-container fli-4 fl-cntr-col">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 16 && index < 20 && getBallForColumn(index);
            })}
          </div>

          <div className="spaceholder-container fli-4 fl-cntr fl-cntr-col">
            {/* empty space. delimit balls column */}
          </div>

          {/* middle video and main balls */}
          <div className="middle-content">
            <div className="main-middle-balls" id="middleBalls" ref={middleBallsRef}>
              
              <div
                className={`position-absolute ${drawResults[0]} ball_first main-ball`}
              />
              <div
                className={`position-absolute ${drawResults[1]} ball_second  main-ball`}
              />
              <div
                className={`position-absolute ${drawResults[2]} ball_third  main-ball`}
              />
              <div
                className={`position-absolute ${drawResults[3]} ball_fourth  main-ball`}
              />
              <div
                className={`position-absolute ${drawResults[4]} ball_fifth  main-ball`}
              />
              <div
                className={``}
                ref={animatedRef}
              />
            </div>

            <div className="background-video">
              {/* {videoState === "START" && (
                <video
                  onEnded={startVideoEnded}
                  id="videoStart"
                  className="fullscreen"
                  autoPlay
                  muted
                >
                  <source src={start} type="video/mp4" />
                </video>
              )} */}
              {videoState === "MIDDLE" && (
                <video className="fullscreen" autoPlay muted loop>
                  <source src={mid} type="video/mp4" />
                </video>
              )}
              {/* {videoState === "END" && (
                <video className="fullscreen" autoPlay muted>
                  <source src={end} type="video/mp4" />
                </video>
              )} */}
            </div>
          </div>

          <div className="justify-flex-end spaceholder-container fli-4 fl-cntr-col">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 19 && index < 23 && getBallForColumn(index);
            })}
          </div>

          <div className="justify-flex-end spaceholder-container fli-4 fl-cntr-col">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 22 && index < 26 && getBallForColumn(index);
            })}
          </div>

          <div className="justify-flex-end spaceholder-container fli-4 fl-cntr-col">
            {state.lifecycleData.Entries.map((item, index) => {
              return index > 25 && index <= 35 && getBallForColumn(index);
            })}
          </div>
        </div>
      </div>
  );
};

export default Drum;
