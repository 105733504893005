import React from "react";
import { useTranslation } from "react-i18next";
const FirstBallTable = ({
  winningPlanArray,
  FirstNumberUOText
}) => {
  const { t } = useTranslation();
  const getValueByBetType = (a, b, c) => {
    let x;
    c.map(item => {
      if (item.BetType === a && item.Specifier === b) x = item.Value;
      return item;
    });
    return x;
  };
  const firstNumberUOText = FirstNumberUOText.replace('-', '').replace('+', '')
  return (
    <div className="luckysix-container">
      {t("betContent.firstBallTable.firstBallHighLow")}
      <div className="pay-table-label-dark pay-table-line">
        {t("betContent.firstBallTable.overOrUnder")} {firstNumberUOText}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.firstBallTable.over")} {firstNumberUOText} - {getValueByBetType(3, 2, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.firstBallTable.under")} {firstNumberUOText} - {getValueByBetType(3, 1, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
    </div>
  );
};

export default FirstBallTable;
