import React from "react";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  ModalMainContent,
  // ModalCloseBtn
} from "./../Modal/Modal";

const JackpotWinnerModal = ({
  showModal,
  openModalEventListener,
  jackpotCode,
  jackpotCurrency='',
  jackpotAmount,
  toggleModal
}) => {
  const { t } = useTranslation();

  if(showModal) {
    setTimeout(toggleModal, 8000)
  }
  return (
    <Modal
      showModal={showModal}
      id={"jackpotWinner"}
      customClass={`withdraw-money`}
      closeModalEventListener={openModalEventListener}
    >
      {/*<ModalCloseBtn closeModalEventListener={toggleModal} />*/}

      <ModalHeader>
        <h2>{t("modalWindows.jackpotWinnModal.title")} </h2>
      </ModalHeader>

      <ModalMainContent>
        <div className="centered-block jackpot-hit align-center">
          <hr />
          <label className="flex-col-21">
            {t("modalWindows.jackpotWinnModal.wonAmount")} <br/>
            <strong>
              {jackpotAmount} {jackpotCurrency}
            </strong>
          </label>
          <hr />
          <strong className="uppercase"> {t("modalWindows.jackpotWinnModal.jackpotCode")}: {jackpotCode}</strong>
          {/*<button*/}
          {/*  onClick={toggleModal}*/}
          {/*  className="btn btn-info"*/}
          {/*>*/}
          {/*  {t("modalWindows.jackpotWinnModal.getMoney")}*/}
          {/*</button>*/}
        </div>
      </ModalMainContent>
    </Modal>
  );
};

export default JackpotWinnerModal;
