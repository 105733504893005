import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

const Timer = ({timeToDrum}) => {
  const [stateTime, setStateTime] = useState({time: {},seconds: timeToDrum - 1});

  const { t } = useTranslation();
  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (3600));

    let divisor_for_minutes = secs % (3600);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds > 9 ? seconds.toString() : "0" + seconds
    };
    return obj;
  };

  const setMainCountdown = (seconds) => {
    const finalCountdown = document.getElementById(
      "finalCountdown"
    );
    if (!finalCountdown) return;
    finalCountdown.innerText = `${seconds}`; 
  }

  useEffect(()=>{
      let timeLeftVar = secondsToTime(stateTime.seconds);
      setStateTime({ ...stateTime, time: timeLeftVar });
      let seconds_ = stateTime.seconds;
      let countDownInterval = setInterval(()=>{
        seconds_ = seconds_ - 1;
        setStateTime({ ...stateTime, time: secondsToTime(seconds_) });
        if (seconds_ <= 11) setMainCountdown(seconds_);
        if (seconds_ <= 0) clearInterval(countDownInterval);
      }, 1000);

      return () => {
        clearInterval(countDownInterval);
        setStateTime({time: {},seconds: stateTime.seconds});
      };
      // eslint-disable-next-line
  }, []);
    
  return (
    <div className="game-timer">
      <span className="timerLabel">{t("header.nextDraw")}</span>
      <span className="timerCountdown">
        {stateTime.time.m}:{stateTime.time.s}
      </span>
    </div>
  );
  
};

export default Timer;
