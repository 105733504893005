import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Jackpot = ({ jackpotValue }) => {
  const { t } = useTranslation();
  const [jackpotesState, setJackpotesState] = useState({superJackpotShadow: false,superJackpotBalanceShadow: false,jackpotShadow: false });

  useEffect(() => {
    setTimeout(() => {
        makeSuperJackpotShadow();
      }, 100)
    return () => { }
    // eslint-disable-next-line
  }, []);


  const makeSuperJackpotShadow = () => {
    setJackpotesState({
      superJackpotShadow: true,
      superJackpotBalanceShadow: true,
      jackpotShadow: false,
    });
    setTimeout(() => { makeJackpotShadow() }, 2000)
  };

  const makeJackpotShadow = () => {
    setJackpotesState({
      superJackpotShadow: false,
      superJackpotBalanceShadow: false,
      jackpotShadow: true,
    });
    setTimeout(() => {
      setJackpotesState({ ...jackpotesState, jackpotShadow: false });
    }, 2000)
    setTimeout(() => {
      makeSuperJackpotShadow();
    }, 6000)
  };

  return (
    <section id="jackpot" className="flic jackpot">
      <div className="flir fl-cntr">
        <div className="jackpot-container fullheight fl-cntr">
          {jackpotValue && <div
            className="flic fli-3 fl-cntr-center"
            data-bind="with: superJackpot"
          >
            <div className="jackpot-label-container">
              <div className={`jackpot-label jackpot-label-text super ${jackpotesState.superJackpotShadow ? "super-jackpot-label-text-shadow" : ""}`}>
                {t('header.jackpot')}
              </div>
            </div>
            <div className={`jackpot-balance ${jackpotesState.superJackpotBalanceShadow}`}>
              {jackpotValue}
            </div>
          </div>
          }
        </div>
      </div>
    </section>
  );
};
// }

export default Jackpot;
