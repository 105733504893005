import React from "react";
import { useTranslation } from "react-i18next";
const FirstFivePreballsTable = ({
  winningPlanArray,
  PreNumbersUOText
}) => {
  const { t } = useTranslation();
  const getValueByBetType = (a, b, c) => {
    let x;
    c.map(item => {
      if (item.BetType === a && item.Specifier === b) x = item.Value;
      return item;
    });
    return x;
  };
  const preNumbersUOText = PreNumbersUOText.replace('-', '').replace('+', '')
  return (
    <div className="luckysix-container">
      {t("betContent.first5PreballsTable.first5Preballs")}
      <div className="pay-table-label-dark pay-table-line">
        {t("betContent.first5PreballsTable.sum5Preballs")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.first5PreballsTable.over")} {preNumbersUOText} - {getValueByBetType(5, 2, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.first5PreballsTable.under")} {preNumbersUOText} - {getValueByBetType(5, 1, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
    </div>
  );
};

export default FirstFivePreballsTable;