import {WINNINGPLAN} from "../containers/App/constantActions";

export const lfstates = {
  DRAW: "DRAW",
  WINNINGPLAN: "BET",
  BET: "BET",
  RESULT: "RESULT",
  JACKPOT_HIT: "JACKPOT_HIT",
};

export const colors = {
  red: "red",
  green: "green",
  blue: "blue",
  purple: "purple",
  brown: "brown",
  yellow: "yellow",
  orange: "orange",
  black: "black",
};

export const colorsByNumber = {
  [colors.red]: [1, 9, 17, 25, 33, 41],
  [colors.green]: [2, 10, 18, 26, 34, 42],
  [colors.blue]: [3, 11, 19, 27, 35, 43],
  [colors.purple]: [4, 12, 20, 28, 36, 44],
  [colors.brown]: [5, 13, 21, 29, 37, 45],
  [colors.yellow]: [6, 14, 22, 30, 38, 46],
  [colors.orange]: [7, 15, 23, 31, 39, 47],
  [colors.black]: [8, 16, 24, 32, 40, 48],
};

export const lfDuration = {
  [lfstates.BET]: 80000,
  [lfstates.DRAW]: 147000,
  [lfstates.RESULT]: 13000
}
