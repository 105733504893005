import React from "react";
import ColorsDiagram from "../progressBar/ColorsDiagram";
import ProgressBarHorizontal from "../progressBar/ProgressBarHorizontal";

import {
  UPDATE_RESULT_LIVE_STATUS
} from "./../../containers/App/constantActions";

import { useGlobalDataContext } from "./../../GlobalDataContext";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  const { state, dispatch } = useGlobalDataContext();
  const { t } = useTranslation();

  React.useEffect(()=>{
    // resset all values
    dispatch({
      type: UPDATE_RESULT_LIVE_STATUS,
      payload: {
        preNumberSum: { value: 0, preNumFinished: false },
        colorsDiagram: [
          { color: "red", count: 0 },
          { color: "green", count: 0 },
          { color: "blue", count: 0 },
          { color: "purple", count: 0 },
          { color: "brown", count: 0 },
          { color: "yellow", count: 0 },
          { color: "orange", count: 0 },
          { color: "black", count: 0 }
        ],
        eventOddCount: { even: 0, odd: 0 }
      },
    });
    return () => {}
  },[dispatch])
  
  const preNumbers = state.lifecycleData.PreNumbersUOText ?
    Number(state.lifecycleData.PreNumbersUOText.replace(',', '.').substring(1, state.lifecycleData.PreNumbersUOText.length - 1)) : 0;

  return (
    <div className="flic">
      <div className="flir fullheight fl-cntr-vcenter">
        <div className="fli-1 align-left left">
          <div className="flir">
            <div className="width-30 padding-3 lato-18-semibold">
              {t('statistics.colorBetting')}
            </div>

            <ColorsDiagram
              colorsCounts={state.resultLiveStatus.colorsDiagram}
            />
          </div>
        </div>

        <div className="fli-3 align-center fl-cntr-center">
          <div className="lato-18-semibold-centered">
            <span className={"preball-sum-title"}>{t('statistics.preballsSum')}</span>
            <span className={"prebals-live-sum"}>
              {state.resultLiveStatus.preNumberSum.value}
            </span>
          </div>
          <div className="lato-18-semibold-centered">
            <span>
            {state.lifecycleData.PreNumbersUOText}{" "}
            </span>
          </div>
          <div className="lato-18-semibold-centered">
            <span
              className={
                state.resultLiveStatus.preNumberSum.value < preNumbers &&
                state.resultLiveStatus.preNumberSum.preNumFinished
                  ? "over-under-live active"
                  : "over-under-live"
              }
            >
              {t('statistics.under')}
            </span>
            <span
              className={
                state.resultLiveStatus.preNumberSum.value > preNumbers &&
                state.resultLiveStatus.preNumberSum.preNumFinished
                  ? "over-under-live active"
                  : "over-under-live"
              }
            >
              {t('statistics.over')}
            </span>
          </div>
        </div>
        <div className="margin-1 fli-1 align-left left">
          <div className="lato-18-semibold">
            <span>{t('statistics.even')}</span>
            <span>{state.resultLiveStatus.eventOddCount.even}</span>
            <span>
              <ProgressBarHorizontal
                fillCount={state.resultLiveStatus.eventOddCount.even}
                maxPossibleCount={5}
              />
            </span>
          </div>
          <div className="lato-18-semibold">
            <span>{t('statistics.odd')}</span>
            <span>{state.resultLiveStatus.eventOddCount.odd}</span>
            <span>
              <ProgressBarHorizontal
                fillCount={state.resultLiveStatus.eventOddCount.odd}
                maxPossibleCount={5}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
