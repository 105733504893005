import React from "react";
import { useTranslation } from "react-i18next";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const getValueByBetType = (betType, specifier, winPlayArray) => {
  return winPlayArray.find(
    (item) => item.BetType === betType && item.Specifier === specifier
  ).Value;
};

const BetsTable = ({
  winningNumbers,
  winningPlanArray,
  PreNumbersUOText,
  FirstNumberUOText,
}) => {
  const { t } = useTranslation();
  let mostFrequentColor = [
    {
      name: t("betContent.betsTable.color1"),
      price: `x ${getValueByBetType(10, 1, [...winningPlanArray])}`, // @todo replace all price values on real WinningPlan gotten from the native app
    },
    {
      name: t("betContent.betsTable.colors2"),
      price: `x ${getValueByBetType(10, 2, [...winningPlanArray])}`,
    },
    // {
    //   name: t("betContent.betsTable.colors4"),
    //   price: `x ${getValueByBetType(10, 4, [...winningPlanArray])}`
    // }
  ];

  let firstBallColor = [
    {
      name: t("betContent.betsTable.color1"),
      price: `x${getValueByBetType(7, 1, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.colors2"),
      price: `x${getValueByBetType(7, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.colors4"),
      price: `x${getValueByBetType(7, 4, [...winningPlanArray])}`,
    },
  ];

  let lastBallColor = [
    {
      name: t("betContent.betsTable.color1"),
      price: `x${getValueByBetType(8, 1, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.colors2"),
      price: `x${getValueByBetType(8, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.colors4"),
      price: `x${getValueByBetType(8, 4, [...winningPlanArray])}`,
    },
  ];

  let extraBets = [
    {
      name: t("betContent.betsTable.preballsNumber"),
      price: `x${getValueByBetType(6, 0, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.moreEvenBalls"),
      price: `x${getValueByBetType(11, 1, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.moreOddBalls"),
      price: `x${getValueByBetType(11, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.prebalSumOdd"),
      price: `x${getValueByBetType(4, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.prebalSumEven"),
      price: `x${getValueByBetType(4, 1, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.preBallSumOver").replace(
        "%value%",
        PreNumbersUOText
      ),
      price: `x${getValueByBetType(5, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.preBallSumUnder").replace(
        "%value%",
        PreNumbersUOText
      ),
      price: `x${getValueByBetType(5, 1, [...winningPlanArray])}`,
    },
  ];

  let firstBall = [
    {
      name: t("betContent.betsTable.firstEvenBall"),
      price: `x${getValueByBetType(2, 1, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.firstOddBall"),
      price: `x${getValueByBetType(2, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.firstBallOver").replace(
        "%value%",
        FirstNumberUOText
      ),
      price: `x${getValueByBetType(3, 2, [...winningPlanArray])}`,
    },
    {
      name: t("betContent.betsTable.firstBallUnder").replace(
        "%value%",
        FirstNumberUOText
      ),
      price: `x${getValueByBetType(3, 1, [...winningPlanArray])}`,
    },
  ];

  // let lastBall = [
  //   {
  //     name: t("betContent.betsTable.lastBallEven"),
  //     price: "x1.9",
  //   },
  //   {
  //     name: t("betContent.betsTable.lastBallOdd"),
  //     price: "x1.9",
  //   },
  //   {
  //     name: t("betContent.betsTable.lastBallOver"),
  //     price: "x1.9",
  //   },
  //   {
  //     name: t("betContent.betsTable.lastBallUnder"),
  //     price: "x1.9",
  //   },
  // ];

  let hitSoonerWinMore = [
    {
      name: t("betContent.betsTable.hitInFirst6"),
      price: `x${winningNumbers[0].Value}`,
    },
    {
      name: t("betContent.betsTable.hitInFirst7"),
      price: `x${winningNumbers[1].Value}`,
    },
    {
      name: t("betContent.betsTable.hitInFirst8"),
      price: `x${winningNumbers[2].Value}`,
    },
  ];

  let systemGame = [
    {
      name: t("betContent.betsTable.playFrom7to10"),
    },
    {
      name: t("betContent.betsTable.hitMoreThan6"),
    },
    {
      name: t("betContent.betsTable.increaseWinning"),
    },
  ];

  let hitTheStars = [
    {
      name: t("betContent.betsTable.hitFirstStar"),
      price: t("betContent.betsTable.winning2"),
    },
    {
      name: t("betContent.betsTable.hitSecondStar"),
      price: t("betContent.betsTable.winning3"),
    },
  ];

  return (
    <div>
      <div className="bets-container">
        <div className="bets-label">
          <div className="lato-48-yellow">
            {t("betContent.betsTable.colorBet")}
          </div>
          <BootstrapTable data={mostFrequentColor} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.mostFrequentColor")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable data={firstBallColor} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.firstBallColor")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable data={lastBallColor} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.lastBallColor")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
        </div>
        <div className="bets-line" />
        <div className="bets-label">
          <div className="lato-48-orange">
            {t("betContent.betsTable.extraBets")}
          </div>
          <BootstrapTable data={extraBets} hover>
            <TableHeaderColumn isKey dataField="name"></TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable data={firstBall} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.firstBall")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          {/*<BootstrapTable data={lastBall} hover>*/}
          {/*  <TableHeaderColumn isKey dataField="name">*/}
          {/*    */}
          {/*    {t("betContent.betsTable.lastBall")}*/}
          {/*  </TableHeaderColumn>*/}
          {/*  <TableHeaderColumn dataField="price"></TableHeaderColumn>*/}
          {/*</BootstrapTable>*/}
        </div>
        <div className="bets-line" />
        <div className="bets-label">
          <div className="lato-48-red">
            {t("betContent.betsTable.hit6from48")}
          </div>
          <BootstrapTable data={hitSoonerWinMore} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.hitSoonerWinMore")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable data={systemGame} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.sysGame")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable data={hitTheStars} hover>
            <TableHeaderColumn isKey dataField="name">
              {t("betContent.betsTable.hitStars")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price"></TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </div>
  );
};

export default BetsTable;
