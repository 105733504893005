import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
// import XHR from 'i18next-xhr-backend'
import languageEN from './locales/en/translate.json'
import languageDE from './locales/de/translate.json';
import languageRU from './locales/ru/translate.json';

i18n
    // .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        lookupQuerystring: 'lang',
        order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      },
      resources: {
        en: languageEN,
        de: languageDE,
        ru: languageRU,
      },
      /* When react i18next not finding any language to as default in borwser */
      fallbackLng: "en",
      /* debugger For Development environment */
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
        formatSeparator: ","
      },
      react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      },
      supportedLngs: ['ru', 'en', 'de'],

    })

export default i18n;