import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LuckySixTable from "./LuckySixTable";
import FirstBallColorTable from "./FirstBallColorTable";
import EvenOrOddTable from "./EvenOrOddTable";
import FirstBallTable from "./FirstBallTable";
import FirstFivePreballsTable from "./FirstFivePreballsTable";
import FirstBallEvenOrOddTable from "./FirstBallEvenOrOddTable";
import HitTheStarsTable from "./HitTheStarsTable";
import HurryUpTable from "./HurryUpTable";
import BetsTable from "./BetsTable";

import { lfstates } from "./../../utils/constants";

import { useGlobalDataContext } from "./../../GlobalDataContext";
import {pause} from "../../utils/pause";

const BetContent = () => {
  const { t } = useTranslation();
  const { state } = useGlobalDataContext();
  const [contentShowSatus, setContentShowSatus] = useState({
    showBetsTable: false,
    showLuckySix: false,
    showColorOfFirstBall: false,
    showMoreEvenOrOdd: false,
    showFirstBallHighOrLow: false,
    showPreballsFirstFive: false,
    showFirstBallEvenOrOdd: false,
    showHitTheStars: false,
    showHurryUp: false,
    showCountDown: false
  });

  const countdownTimeSeconds = 11;
  const countdownBetsTable = 12;

  useEffect(() => {
    let isSubscribed = true;
    if (state.currentLFState === lfstates.BET && isSubscribed)
      showResultScreens(Object.keys(contentShowSatus), false)
    return () => { isSubscribed = false }
    // eslint-disable-next-line
  }, [state.currentLFState]);


  const showResultScreens = async (array, stop) => {
    if (stop) return false
    let oneScreenInterval = state.winningPlanData.Countdown  ? ((state.winningPlanData.Countdown - countdownTimeSeconds - countdownBetsTable) / (array.length - 2)) * 1000 : 8000;

    const timeOutTime = (name) => {
      if(name === "showBetsTable") return 500;
      if(name === "showLuckySix") return countdownBetsTable * 1000;
      else return oneScreenInterval
    };

    for (const item of array) {
      await pause(timeOutTime(item));
      showOneScreen(item);
    }
    return false
  }

  const showOneScreen = (keyname) => {
    setContentShowSatus({
      showBetsTable: keyname === "showBetsTable" ? true : false,
      showLuckySix: keyname === "showLuckySix" ? true : false,
      showColorOfFirstBall:
          keyname === "showColorOfFirstBall" ? true : false,
      showMoreEvenOrOdd: keyname === "showMoreEvenOrOdd" ? true : false,
      showFirstBallHighOrLow:
          keyname === "showFirstBallHighOrLow" ? true : false,
      showPreballsFirstFive:
          keyname === "showPreballsFirstFive" ? true : false,
      showFirstBallEvenOrOdd:
          keyname === "showFirstBallEvenOrOdd" ? true : false,
      showHitTheStars: keyname === "showHitTheStars" ? true : false,
      showHurryUp: keyname === "showHurryUp" ? true : false,
      showCountDown: keyname === "showCountDown" ? true : false
    });
  };


  return (
    <div
      className="width-90"
      style={{
        display: state.currentLFState === lfstates.BET ? "block" : "none"
      }}
    >
      <div className="next-draw-label">{t("betContent.nextDraw")} {state.winningPlanData.Round}</div>

      <div className="next-draw-line" />

      {contentShowSatus.showBetsTable ? (
        <BetsTable
          winningNumbers = { state.winningPlanData.WinningNumbers }
          winningPlanArray={state.winningPlanData.WinningPlan}
          PreNumbersUOText={state.winningPlanData.PreNumbersUOText}
          FirstNumberUOText={state.winningPlanData.FirstNumberUOText}
        />
      ) : (
        ""
      )}
      {contentShowSatus.showBetsTable ? (
        ""
      ) : (
        <div className="pay-table-pattern" />
      )}
      <div className="pay-table pay-table-label">
        {contentShowSatus.showLuckySix ? <LuckySixTable winningNumbers = { state.winningPlanData.WinningNumbers } /> : ""}
        {contentShowSatus.showColorOfFirstBall ?
          <FirstBallColorTable winningPlanArray={state.winningPlanData.WinningPlan}/> : 
          ""}
        {contentShowSatus.showMoreEvenOrOdd ? 
          <EvenOrOddTable winningPlanArray={state.winningPlanData.WinningPlan} /> : 
          ""}
        {contentShowSatus.showFirstBallHighOrLow ? 
          <FirstBallTable 
            winningPlanArray={state.winningPlanData.WinningPlan} 
            FirstNumberUOText={state.winningPlanData.FirstNumberUOText}
            /> : 
          ""}
        {contentShowSatus.showPreballsFirstFive ? (
          <FirstFivePreballsTable 
            winningPlanArray={state.winningPlanData.WinningPlan}
            PreNumbersUOText={state.winningPlanData.PreNumbersUOText} />
        ) : (
          ""
        )}
        {contentShowSatus.showFirstBallEvenOrOdd ? (
          <FirstBallEvenOrOddTable 
            winningPlanArray={state.winningPlanData.WinningPlan} />
        ) : (
          ""
        )}
        {contentShowSatus.showHitTheStars ? <HitTheStarsTable /> : ""}
        {contentShowSatus.showHurryUp ? <HurryUpTable /> : ""}
        {contentShowSatus.showCountDown && (
          <div className="countdown-label" id="finalCountdown">
            {countdownTimeSeconds - 1}
          </div>
        )}
      </div>
    </div>
  );
};

export default BetContent;
