import React from "react";
import { useTranslation } from "react-i18next";
const FirstScreen = () => {
  const { t } = useTranslation();
  return (
    <div
      className="width-90"
    >
      <div className="pay-table pay-table-label">
        <div className="pay-table-pattern" style={{position: 'fixed', top: '0', backgroundSize: '80%'}} />
        <div className="luckysix-container">
          <div  className="pay-table-label pay-table-line" 
              style={{paddingTop: '10px', marginBottom: '10px', fontSize: '3vw'}}>
              {t('firstScreenMsg')}
          </div>
          <div className="pay-table-label pay-table-line" style={{height: '30px'}}/>
        </div>
      </div>
    </div>
  );
};

export default FirstScreen;
