import React, { useState, useEffect } from "react";

const ProgressBarHorizontal = ({ fillCount, maxPossibleCount }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage((fillCount * 100) / maxPossibleCount);
    return () => {}
    // eslint-disable-next-line
  }, [fillCount]);

  return (
    <div className="progress-bar-horizontal">
      <div className="filler-horizontal" style={{ width: `${percentage}%` }} />
    </div>
  );
};

export default ProgressBarHorizontal;
