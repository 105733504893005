import React from "react";
import { useTranslation } from "react-i18next";
const FirstBallEvenOrOddTable = ({
  winningPlanArray
}) => {
  const { t } = useTranslation();
  const getValueByBetType = (a, b, c) => {
    let x;
    c.map(item => {
      if (item.BetType === a && item.Specifier === b) x = item.Value;
      return item;
    });
    return x;
  };

  return (
    <div className="luckysix-container">
      {t("betContent.firstBallEvenOddTable.firstBallEvenOdd")}
      <div className="pay-table-label-dark pay-table-line">
        {t("betContent.firstBallEvenOddTable.isFirstBallEvenOdd")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.firstBallEvenOddTable.evenNumber")} - {getValueByBetType(2, 1, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.firstBallEvenOddTable.oddNumber")} - {getValueByBetType(2, 2, [...winningPlanArray])} x {t("stakeUPER")}
      </div>
    </div>
  );
};

export default FirstBallEvenOrOddTable;
