import React from "react";
import { useTranslation } from "react-i18next";

const LuckySixTable = ({winningNumbers}) => {
  const { t } = useTranslation();
  return (
    <div className="luckysix-container">

      {t("betContent.luckySix")}
      <div className="pay-table-label-dark pay-table-line">
        {t("betContent.betsTable.hitSoonerWinMore")}
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.luckySixTable.hitFirst6")} - { winningNumbers[0].Value }
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.luckySixTable.hitFirst7")} -  { winningNumbers[1].Value }
      </div>
      <div className="pay-table-label-dark-smaller">
        {t("betContent.luckySixTable.hitFirst8")} -  { winningNumbers[2].Value }
      </div>
    </div>
  );
};

export default LuckySixTable;
